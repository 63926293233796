@import "../../style/typography.scss";
@import "../../style/util.scss";
.new {
  width: 100%;
  display: flex;
}

.newContainer {
  flex: 8;
}

.top,
.bottom-orcamento {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
}

.top {
  display: flex;
}

.bottom-orcamento {
  grid-template-columns: 1fr;
  padding: 50px;
}

.h1 {
  color: lightgray;
  font-size: 20px;
}

.formInput {
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
}

.planoTratamento {
  &__item {
    display: grid;
    grid-template-columns: 40% 15% 20% 25%;
    margin-bottom: 20px;

    &--headerDiv {
      margin-bottom: 50px;
    }

    &--header {
      @include font-primary-semiBold;
    }
  }
}

.editPlanoTratamento {
  &__item {
    display: grid;
    grid-template-columns: 35% 10% 15% 25% 15%;
    margin-bottom: 20px;
    align-items: center;

    &--headerDiv {
      margin-bottom: 50px;
    }

    &--header {
      @include font-primary-semiBold;
    }
  }
}

.orcamento {
  &__item {
    display: grid;
    grid-template-columns: 60% 10% 10% 10% 10%;
    font-size: 14px;

    &--particular {
      grid-template-columns: 70% 15% 15%;
    }

    &--header {
      @include font-primary-semiBold;
      font-size: 12px;
      color: $color-grey-dark;
    }
  }

  &__item--headerDiv,
  &__item {
    border-bottom: 1px solid #88959e5c;
    padding: 15px 0;
  }
}

.singleAvaliacao {
  &__item {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    margin-bottom: 20px;

    &--headerDiv {
      margin-bottom: 50px;
    }

    &--header {
      @include font-primary-semiBold;
    }
  }
}

.avaliacao {
  &__item {
    display: grid;
    grid-template-columns: 46% 17% 27% 10%;
    column-gap: 10px;
    align-items: center;
  }
}

.add-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  border: none;
  background: linear-gradient($color-blue, #638de5);
  color: white;
  box-shadow: 0 1px 3px 0px rgba(#0f0f0f, 0.5), inset 0 1px rgba(#fff, 0.2);
  transition: all 0.2s;

  &:hover {
    background: linear-gradient($color-blue-dark, #638de5);
  }

  &:active {
    box-shadow: none;
    transform: scale(0.97);
  }
}

.pOrcamento-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  label {
    @include font-primary-bold;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 5px;

    input {
      max-width: 100%;
    }
  }
}

.pPlanoTratamento-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;

  &__item {
    display: grid;
    grid-template-columns: 20% 1fr;
    column-gap: 0;
    align-items: center;

    input {
      max-width: 100%;
    }
  }
}

.tituloListaProcedimentos {
  @include font-primary-semiBold;
  margin-bottom: 25px;
  font-size: 18px;

  &--2 {
    display: flex;
    margin-top: 20px;
  }
}

.orcamento__areaPagamento {
  &__titulo {
    display: flex;
    @include font-primary-semiBold;
    margin: 20px 0;
    font-size: 14px;
  }

  &__tituloDesconto {
    @include font-primary-regular;
    font-size: 12px;
  }

  &__desconto {
    display: flex;
    margin-top: 5px;
    margin-bottom: 15px;
    select,
    input {
      border-radius: 4px;
      padding: 5px 10px;
      border: 1px solid $color-grey-dark;
    }
    input {
      width: 100%;
    }
    select {
      margin-right: 10px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 3fr 2fr 4fr;

    &--1 {
      textarea {
        border-radius: 4px;
        width: 100%;
        border: 1px solid $color-grey-dark;
      }
    }

    &--2 {
      border-radius: 4px;
      background-color: #fcfcfe;
      padding: 15px;
      border: 1px solid rgba(136, 149, 158, 0.3607843137);
      display: flex;
      flex-direction: column;
      gap: 25px;

      &__line {
        padding-bottom: 12px;
        border-bottom: 1px dotted $color-grey-dark;
        display: flex;
        justify-content: space-between;
        font-size: 13px;

        &:last-child {
          border-bottom: none;
        }

        &__title-big {
          text-transform: uppercase;
          @include font-primary-semiBold;
        }
      }

      &__titulo {
        @include font-primary-regular;
        font-size: 12px;
        color: $color-grey-dark;
      }
    }
  }
}

.avaliacaoPickMedcoContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  justify-content: center;
  margin-bottom: 20px;

  &__medicoHeader {
    @include font-primary-semiBold;
    font-size: 14px;
  }
  select {
    max-width: 300px !important;
  }
}

.editavaliacaoPickMedcoContainer {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;

  &__element {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

    #select_medicoResponsavel_editar {
      width: 300px;
    }
  }

  &__medicoHeader {
    @include font-primary-semiBold;
    font-size: 14px;
  }
}
