.modalEditarMajoracao {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    &__inputText {
      width: 100%;
      margin-bottom: 10px;
    }

    &__valor {
      font-weight: bold;
      font-size: 16px;
      color: #2e4da7;
    }
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input[type="checkbox"] {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }

    label {
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__dadosOrcamento {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    margin-bottom: 30px;

    &__titulo {
      grid-column: 1 / 4;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #333;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }

    &__subtitulo {
      font-weight: 600;
      font-size: 14px;
      color: #555;
      text-align: center;
    }

    &__linha {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      border-bottom: 1px solid #f0f0f0;

      &--right {
        text-align: right;
      }
    }

    &__legenda {
      font-size: 14px;
      color: #444;
    }

    &__valor {
      font-weight: 500;
      color: #2e4da7;
    }
  }
}

#modal__editar-majoracao {
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;

  .fluxo-atendimento__modal__titulo {
    margin-bottom: 20px;
    color: #2e4da7;
  }
}
