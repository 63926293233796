@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.fluxoCaixa__resumo {
  &__container {
    margin-top: 40px;
    padding: 20px;
    border-radius: 4px;
    background-color: $color-grey-light;
    color: $color-grey-dark;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__cabecalho {
    font-size: 16px;
    padding-bottom: 7px;
    border-bottom: 1.5px solid $color-grey-dark;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 16px;
    @include font-primary-semiBold;
    margin-bottom: 10px;
  }

  &__list {
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 200px;

    &__left,
    &__right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &__item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.financeiro__ocultarBtn {
  padding: 3px 10px;
  border: 1px solid $color-blue;
  color: $color-blue;
  border-radius: 4px;
  cursor: pointer;

  &--red {
    color: crimson;
    border: 1px solid crimson;

    &:hover {
      background-color: crimson;
      color: white;
    }
  }

  &--blue {
    &:hover {
      background-color: $color-blue;
      color: white;
    }
  }
}
