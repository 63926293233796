.modalImportarXlsStock__errorContainer {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #f8d7da;
  border-radius: 5px;
  background-color: #fff5f5;
  color: #721c24;
  max-height: 300px;
  overflow-y: auto;
}

.modalImportarXlsStock__errorHeader {
  margin-bottom: 15px;
}

.modalImportarXlsStock__errorDetails h4 {
  margin-bottom: 10px;
}

.modalImportarXlsStock__errorTable {
  width: 100%;
  overflow-x: auto;
}

.modalImportarXlsStock__errorTable table {
  width: 100%;
  border-collapse: collapse;
}

.modalImportarXlsStock__errorTable th,
.modalImportarXlsStock__errorTable td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.modalImportarXlsStock__errorTable th {
  background-color: #f2f2f2;
}

.modalImportarXlsStock__errorTable tr:nth-child(even) {
  background-color: #f8f8f8;
}

.modalImportarXlsStock__errorTable ul {
  margin: 0;
  padding-left: 20px;
}
