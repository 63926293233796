@import "../../../../../style/typography.scss";

.relatorio__filtros__container {
  display: flex;
  align-items: center;
  gap: 15px;

  &__btn {
    padding: 9px 15px;
    border: none;
    border-radius: 2px;
    color: white;
    font-size: 16px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: all 0.3s;

    &--blue {
      background-color: $color-blue;
      &:hover {
        background-color: #104570;
      }
    }

    &--green {
      background-color: #55b983;
      &:hover {
        background-color: #47986c;
      }
    }

    &--red {
      background-color: crimson;
      &:hover {
        background-color: rgb(185, 13, 47);
      }
    }
  }
}
