@import "../../../../style/typography.scss";

.expirou {
  color: crimson;
}

.ajustar {
  color: orange;
}

.resumoRelatorioOrcamentos {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  &__titulo {
    grid-column: 1/-1;
  }
}
