@import "../../../../../style/colors.scss";
@import "../../../../../style/typography.scss";
@import "../../../../../style//util.scss";

.singleContainer__info-geral {
  display: flex;
  height: 330px;
  max-width: 100%;
  width: 100%;
  gap: 15px;
  margin-bottom: 10px;
}
.singleContainer__users-face {
  position: relative;
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  align-items: center;

  .profileName {
    @include font-primary-bold;
    font-size: 22px;
    color: $color-dark;
    margin: 10px 0 3px 0;
    text-align: center;
  }

  .profileEmail {
    @include font-primary-semiBold;
    font-size: 13px;
    color: $color-grey-dark;
    font-weight: 500;
  }

  .profileOther {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 10px 0 15px 0;

    &1,
    &2 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &1 {
      border-right: solid 2px $color-grey-other;
    }

    .number {
      @include font-primary-bold;
      font-size: 20px;
      color: $color-dark;
    }

    .text {
      @include font-primary-regular;
      font-weight: 500;
      font-size: 13px;
      color: $color-grey-dark;
    }
  }

  .single-big-btns {
    display: flex;
    width: 100%;
    gap: 5px;

    .single-big-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 0;
      border-radius: 4px;
      font-size: 13px;
      @include font-secondary-bold;
      cursor: pointer;
      width: 50%;
    }

    .marcacao-urgencia-btn {
      color: $color-red;
      border: solid 2px $color-red;

      &:hover {
        background-color: $color-red;
        color: white;
      }
    }

    .emUrgencia-btn {
      background-color: $color-red;
      color: white;
      cursor: pointer;

      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      animation: pulse 2s infinite;
    }

    .marcacao-avaliacao-btn {
      color: $color-blue;
      border: solid 2px $color-blue;

      &:hover {
        background-color: $color-blue;
        color: white;
      }
    }
  }

  .single-anamnese {
    &--div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 0;
      border-radius: 4px;
      font-size: 14px;
      @include font-primary-bold;
      cursor: pointer;
      width: 90%;
      color: $color-blue;
      border: solid 2px $color-blue;

      &:hover {
        background-color: $color-blue;
        color: white;
      }
    }
  }

  .profileImg {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.singleContainer__users-info {
  flex: 6;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;

  &--division {
    &:not(:last-child) {
      border-bottom: 1.5px solid $color-grey-light;
    }
    flex: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .userInfo-box {
      display: flex;
      flex-direction: column;
      gap: 4px;
      @include font-primary-semiBold;

      &_title,
      &_description {
        text-transform: capitalize;
        font-size: 13px;
      }

      &_title {
        color: $color-grey-dark;
      }

      &_description {
        color: $color-dark;
      }
    }
  }
}
.singleContainer__users-anamnese-info {
  flex: 6;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  justify-content: center;
  position: relative;

  .anamnese__texto {
    @include font-primary-regular;
    line-height: 2;
    color: $color-grey-dark;
  }
}

.single-btn-newAnamnese {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  background-color: $color-blue;
  color: white;
  @include font-primary-regular;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: $color-blue;
    background-color: $color-white;
    border: 2px solid $color-blue;
  }
}

.single__container__proximos-agendamentos {
  font-size: 13px;
  @include font-secondary-semiBold;
}

.gUserIconstyle {
  stroke: none;
  stroke-width: 0;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: none;
  fill-rule: nonzero;
  opacity: 1;
}

.pathUserIconStyle {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: $color-blue;
  fill-rule: nonzero;
  opacity: 1;
}
